<template lang="pug">
  div
    TheHeader
    v-layout.password_change(align-center, justify-center, column, fill-height)
      v-form(ref="form", v-model="valid", @submit.prevent="")
        v-layout(column)
          .password_change__title {{ $t('messages.inform.welcomeBack') }}
          .password_change__subtitle {{ $t('messages.success.passwordChangeCodeCorrect') }}
          .max_width
            v-text-field(
              type="password",
              v-model="password",
              :rules="[rules.required, rules.password]",
              @input="() => errors = []",
              :label="$t('formFields.newPassword')",
              required)
            v-text-field(
              type="password",
              v-model="passwordAgain",
              :error-messages="passwordMatchError()",
              @input="() => errors = []",
              :label="$t('formFields.newPasswordAgain')",
              required)
            v-btn(:disabled="!valid", @click="submitForm", type="submit") {{ $t('actions.renewPassword') }}
          .errors(v-if="!Array.isArray(errors) && errors") #[strong {{ errors }}]
          .errors(v-else-if="errors.length", v-for="error in errors") #[strong {{ error.title }}] #[span(v-html="error.detail")]
</template>

<script>
import TheHeader from '@/components/TheHeader';
import REGEXP from '@/regexp';

export default {
  components: { TheHeader },
  data() {
    return {
      valid: false,
      password: '',
      passwordAgain: '',
      errors: []
    };
  },
  methods: {
    passwordMatchError() {
      if (this.password.length < 8) return '';

      if (this.password.length >= 8 && !this.passwordAgain) return this.$t('validation.required');

      return (this.password === this.passwordAgain) ? '' : this.$t('validation.passwordsDoNotMatch');
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        if (this.passwordKey) {
          const payload = {
            data: {
              type: 'reset-passwords',
              attributes: {
                key: this.passwordKey,
                password: this.password
              }
            }
          };

          this.$store.dispatch('user/resetPassword', payload)
            .then(() => this.$router.push('/password-changed'))
            .catch(errors => { this.errors = errors; });
        } else {
          this.errors = [{
            title: this.$t('messages.error.secretCodeRequiredTitle'),
            detail: this.$t('messages.error.secretCodeRequiredText')
          }];
        }
      }
    }
  },
  computed: {
    passwordKey() {
      return this.$store.state.user.passwordKey;
    },
    rules() {
      return {
        required: v => !!v || this.$t('validation.required'),
        password: v => REGEXP.password.test(v) || this.$t('validation.password', { min: 8 }),
        match: v => v === this.password || this.$t('validation.passwordsDoNotMatch')
      };
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .password_change {
    max-width: 400px;
    min-height: 100vh;
    padding: 0 10px;
    margin: auto;
    text-align: left;
    .v-form { width: 100%; }

    &__title,
    &__subtitle {
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 50px;
      line-height: 1;
      color: $light-theme-primary-text;
      text-align: left;
      @media screen and (max-width: 400px) { font-size: 28px; }
    }

    &__subtitle {
      font-weight: normal;
      font-size: 20px;
    }

    &__errors {
      color: $error-color;
    }
  }

  .max_width {
    width: 100%;
    max-width: 350px;
    margin: auto;
  }

  .errors {
    margin-top: 20px;
    color: $error-color;
  }
</style>
